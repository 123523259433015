import { IUser } from './user';

export const MR_PREVIEW_EMAIL = 'mr.preview@example.com';

export const PREVIEW_TAG = '__preview';

export function isMrPreview(user: IUser): boolean {
  if (!user) {
    return false;
  }

  return Boolean(user.email === MR_PREVIEW_EMAIL || user.tags?.includes(PREVIEW_TAG));
}
