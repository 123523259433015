<template>
  <custom-field-input-base :field="field" v-model="value" type="phone"> </custom-field-input-base>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-phone',
  mixins: [customFieldTypeMixin]
};
</script>
