/* eslint-disable no-undefined */

/**
 * Safely converts an object to a JSON string, handling circular references.
 *
 * This function serializes an object to a JSON string while detecting and
 * handling circular references to prevent errors. Circular references are
 * replaced with `undefined` remove them from the resulting JSON string.
 *
 * @param obj - The object to be serialized.
 * @param indent - Optional number of spaces to use for indentation in the resulting JSON string.
 * @returns {string} The JSON string representation of the object.
 */
export function safeStringify(obj: unknown, indent?: number): string {
  const seen = new WeakSet();
  const replacer = (_: string, value: any) => {
    if (seen.has(value)) {
      return undefined;
    }
    if (typeof value === 'object' && value !== null) {
      seen.add(value);
    }
    return value;
  };

  return JSON.stringify(obj, replacer, indent);
}

/**
 * Safely parse a string into a json object without throwing exceptions.
 * @param text - The text to be parsed
 * @returns The parsed object if everything was ok, or null if there was an error.
 */
export function safeParse<T>(text: string): T | null {
  try {
    return JSON.parse(text) as T;
  } catch {
    return null;
  }
}
