<template>
  <v-text-field
    :data-testid="field.testId"
    hide-details="auto"
    :required="field.required"
    :class="{ required: field.required }"
    v-model="value"
    clearable
    @keydown="handleKeydown"
    :label="field.label"
    :title="field.description"
    :placeholder="field.placeholder ?? field.label"
    :persistent-placeholder="Boolean(field.placeholder)"
    :rules="validationRules"></v-text-field>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
export default {
  mixins: [customFieldTypeMixin],
  methods: {
    handleKeydown(e) {
      if (
        this.novaCore.CustomFieldType[this.novaCore.upperFirst(this.fieldType)] ===
        this.novaCore.CustomFieldType.Number
      ) {
        if (this.disallowedNumberCharacters.includes(e.key)) {
          e.preventDefault();
        }
      }
    }
  }
};
</script>
