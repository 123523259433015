import { createStore } from 'vuex';

export default createStore({
  state: {
    isMilitaryTimeEnabled: false
  },
  mutations: {
    setMilitaryTime(state, value) {
      state.isMilitaryTimeEnabled = value;
    }
  },
  actions: {
    setMilitaryTime({ commit }, value) {
      commit('setMilitaryTime', value);
    }
  },
  getters: {
    isMilitaryTimeEnabled(state) {
      return state.isMilitaryTimeEnabled;
    }
  }
});
