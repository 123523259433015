export interface IAssetVisitEvent {
  id: string;
  assetVisitId: string;
  eventType: AssetVisitEventType;
}

export enum AssetVisitEventType {
  NotArrived = 'NotArrived',
  Arrived = 'Arrived',
  Docked = 'Docked',
  Departed = 'Departed',
  Canceled = 'Canceled'
}

export const AssetVisitEventOrder = [
  AssetVisitEventType.NotArrived,
  AssetVisitEventType.Arrived,
  AssetVisitEventType.Docked,
  AssetVisitEventType.Departed,
  AssetVisitEventType.Canceled
] as const;
