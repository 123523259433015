<template>
  <v-autocomplete
    :value="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    @input="value => $emit('input', value || '')"
    :placeholder="field.description"
    :label="field.label"
    clearable
    :menu-props="{ maxHeight }"
    open-on-clear></v-autocomplete>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
import { isMobile } from '@/util';

export default {
  name: 'custom-field-dropdown',
  mixins: [customFieldTypeMixin],
  computed: {
    maxHeight() {
      return isMobile() ? 150 : 500;
    }
  }
};
</script>
