export type Pagination = {
  size: number;
  page: number;
  sortColumn?: string;
};

export const defaultPagination: Pagination = {
  size: 15,
  page: 1
};
