<template>
  <v-autocomplete
    :required="field.required"
    v-model="field.value"
    :rules="validationRules"
    :items="field.dropDownValues"
    clearable
    :placeholder="field.description"
    :label="field.label"
    open-on-clear
    :menu-props="{ maxHeight }"
    multiple></v-autocomplete>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
import { isMobile } from '@/util';

export default {
  mixins: [customFieldTypeMixin],
  computed: {
    maxHeight() {
      return isMobile() ? 150 : 500;
    }
  }
};
</script>
