<template>
  <custom-field-input-base
    :field="field"
    v-model.number="value"
    type="number"></custom-field-input-base>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-number',
  mixins: [customFieldTypeMixin],

  data() {
    return {
      value: this.field.value
    };
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    }
  }
};
</script>
