<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',

  data() {
    return {
      //
    };
  },
  beforeMount() {
    this.mixpanel.init();
  }
});
</script>
<style lang="scss">
@import '@/assets/main.css';
@import '@/styles/main.scss';
</style>
