import type { ComponentInternalInstance } from 'vue';

interface ComponentOptions {
  $store: unknown;
}

const globalMixin = {
  beforeCreate(this: ComponentInternalInstance & ComponentOptions) {
    const store = this.appContext.provides.store;
    if (store) {
      this.$store = store;
    } else {
      console.error('Vuex store not found. Make sure to provide the store.');
    }
  }
};

export default globalMixin;
