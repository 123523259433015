import { defineComponent } from 'vue';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

import { mixpanelToken } from '@/config';

export interface MixpanelMixin {
  mixpanel: {
    api: Mixpanel;
    init: () => void;
    track: (event: string, data?: Record<string, any>) => void;
    isDev: boolean;
  };
}

export default defineComponent({
  data(): MixpanelMixin {
    return {
      mixpanel: {
        api: mixpanel,
        init: () => this.init(),
        track: (event: string, data?: Record<string, any>) => this.track(event, data),
        isDev: import.meta.env.DEV
      }
    };
  },
  methods: {
    init() {
      if (!mixpanelToken) {
        return;
      }
      mixpanel.init(mixpanelToken, { debug: true });
    },
    track(event: string, data = {}) {
      if (this.mixpanel.isDev) {
        console.log('Tracking Mixpanel Event Debug:\n', { event, data });
      }
      if (!mixpanelToken) {
        return;
      }
      try {
        mixpanel.track(event, data);
      } catch (e) {
        console.log('MP Event Error', e);
      }
    }
  }
});
