export enum SpotType {
  PARKING = 'parking',
  DOCKING = 'docking'
}

export enum SpotStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  ASSIGNED = 'assigned',
  UNKNOWN = 'unknown'
}

export enum SpotEventTypeEnum {
  ASSIGNMENT = 'assignment',
  RELEASE = 'release',
  CLOSE = 'close',
  OPEN = 'open',
  OTHER = 'other'
}

export interface ISpot {
  id: string;
  isActive: boolean;
  code: string;
  spotAssignments: ISpotAssignment[];
}

export interface ISpotAssignment {
  id: string;
  spotId: string;
  assetVisitId: string;
}

export function getSpotStatus(spot: ISpot): SpotStatus {
  if (spot.isActive === false) {
    return SpotStatus.CLOSED;
  }

  if (spot.isActive === true && !spot.spotAssignments?.length) {
    return SpotStatus.OPEN;
  }

  if (spot.isActive === true && spot.spotAssignments?.length > 0) {
    return SpotStatus.ASSIGNED;
  }

  return SpotStatus.UNKNOWN;
}

export const YARD_SPOT_CODE_MAX_LENGTH = 5;
