<template>
  <div class="my-2 pl-2">
    <document-upload-button
      :required="field.required"
      :rules="validationRules"
      :placeholder="field.description"
      :label="field.label"
      @uploaded="v => (field.value = v)"
      :appointment-identifier="appointmentIdentifier"
      :warehouse-id="warehouseId"></document-upload-button>
    <p class="my-2">
      Maximum
      <strong>50MB</strong>
      in size. Only
      <strong>ONE image</strong>
      ,
      <strong>text</strong>
      or
      <strong>spreadsheet</strong>
      allowed.
    </p>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-document',
  mixins: [customFieldTypeMixin],
  props: {
    appointmentIdentifier: {
      type: String,
      required: false
    },
    warehouseId: {
      type: String,
      required: true
    }
  }
};
</script>
