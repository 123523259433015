<template>
  <div class="v-input v-input--horizontal v-field--variant-solo">
    <label class="v-label v-field-label mx-4">{{ field.label }} - ({{ field.description }})</label>
    <div class="v-input__control">
      <datepicker
        v-model="value"
        format="yyyy-MM-dd"
        :required="field.required"
        class="mt-11 mb-3 ml-4"
        :state="!!value"
        model-type="yyyy-MM-dd"
        auto-apply
        :enable-time-picker="false"></datepicker>
    </div>
  </div>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'CustomFieldDate',
  mixins: [customFieldTypeMixin]
};
</script>
