export interface IVersion {
  major: number;
  minor: number;
  patch: number;
  commit: string;
}

export function versionToString(v: IVersion): string {
  return `v${v.major}.${v.minor}.${v.patch} - ${v.commit}`;
}
