import { GlobalLimits } from './limits';

export const MAX_CUSTOM_TAGS_PER_ORG = GlobalLimits.MAX_CUSTOM_TAGS_PER_ORG.value;

export interface ICustomTag {
  name: string;
  color: string;
  textColor: string;
  notificationEnabled?: boolean; // If undefined this should be treated as falsey
}
