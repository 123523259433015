// @ts-strict-ignore
import { SearchResponseBody } from '@elastic/elasticsearch/lib/api/types';
import { IUser } from './user';
import { hasNestedField, tryParseJson } from './js-helpers';

export interface ISearchResponse extends SearchResponseBody {
  items?: { [key: string]: any }[];
  total?: number;
}

export interface ISearchAdapter {
  searchAppointments(user: IUser, body);
}

export function isSlowSearch(queryString: string) {
  const searchKeys = [
    '$cont',
    '$starts',
    '$ends',
    '$excl',
    '$contL',
    '$startsL',
    '$endsL',
    '$exclL'
  ];
  // queryString comes as a string, but hasNestedField wants an object so it can stringify it
  // If any of the searchKeys exist in the queryString, return true
  return searchKeys.some(key => hasNestedField(tryParseJson(queryString), key));
}
