import { AppointmentStatus } from './status';
import { AppointmentType } from './appointment';
import { CustomFormsFeatures } from './custom-forms';

export enum ReportSearchDefinedRanges {
  TODAY = 'today',
  WTD = 'wtd',
  MTD = 'mtd',
  YTD = 'ytd'
}

export interface IReportSearches {
  [titleSlug: string]: IReportSearch;
}

export interface IReportSearch {
  title: string;
  createdAt?: Date;
  filters: IReportSearchFilters;
}

export interface IReportSearchFilters {
  toDate?: Date;
  fromDate?: Date;
  dateField: ReportDateFields;
  warehouseIds: string[];
  loadTypeIds?: string[];
  dockIds?: string[];
  appointmentCreatorTypes?: AppointmentCreatorTypeReportOptions[];
  carrierIds?: string[];
  exportFields: ExportableFieldsType[];
  statuses: AppointmentStatus[];
  definedRange?: ReportSearchDefinedRanges;
  tags: string[];
  appointmentTypes: AppointmentType[];
  allCarriers: boolean;
}

export function definedRangeMetadata(range: ReportSearchDefinedRanges) {
  return {
    [ReportSearchDefinedRanges.TODAY]: {
      description: 'Appointments Scheduled for Today',
      label: 'Today',
      startOf: 'day'
    },
    [ReportSearchDefinedRanges.WTD]: {
      description: 'Week to Date Starting on Sunday',
      label: 'Week to Date',
      startOf: 'week'
    },
    [ReportSearchDefinedRanges.MTD]: {
      description: 'Month to Date Starting on the 1st',
      label: 'Month to Date',
      startOf: 'month'
    },
    [ReportSearchDefinedRanges.YTD]: {
      description: 'Year to Date Starting January 1st',
      label: 'Year to Date',
      startOf: 'year'
    }
  }[range];
}

enum RegularExportableFields {
  CONFIRMATION_NUMBER = 'Confirmation #',
  APPOINTMENT_ID = 'Appointment ID',
  APPOINTMENT_DATE = 'Appt Date',
  APPOINTMENT_TIME = 'Appt Time',
  APPOINTMENT_TIMEZONE = 'Appt Timezone',
  APPOINTMENT_TYPE = 'Appt Type',
  SCHEDULED_DURATION = 'Scheduled Duration (mins)',
  STATUS = 'Status',
  ARRIVAL_DATE = 'Arrival Date',
  ARRIVAL_TIME = 'Arrival Time',
  DEPARTURE_DATE = 'Departure Date',
  DEPARTURE_TIME = 'Departure Time',
  DWELL_TIME = 'Dwell Time (mins)',
  ON_TIME = 'On-Time (mins)',
  IN_PROGRESS_DATE = 'In Progress date', //the date of when the appt was marked as In Progress
  IN_PROGRESS_TIME = 'In Progress time', //the time of when the appt was marked as In Progress
  WAIT_TIME = 'Wait time (mins)', //the time that elapses between Arrival time and when the app is marked as In Progress
  PROCESSING_TIME = 'Processing time (mins)', // the time that elapses between In Progress time and when the appt is marked as Completed
  WAREHOUSE = 'Warehouse',
  DOCK = 'Dock',
  LOAD_TYPE = 'Load Type',
  LOAD_TYPE_DIRECTION = 'Direction',
  RESCHEDULE_COUNT = 'Reschedule Count',
  REFERENCE_NUMBER = 'Reference Number',
  CREATED_BY = 'Created By',
  CREATOR_TYPE = 'Creator Type',
  CREATION_DATE = 'Creation Date',
  LAST_UPDATED_BY = 'Last Updated By',
  LAST_UPDATE_DATE = 'Last Update Date',
  LAST_UPDATE_TIME = 'Last Update Time',
  CARRIER_COMPANY = 'Carrier Company',
  CONTACT_NAME = 'Contact Name',
  CONTACT_EMAIL = 'Contact Email',
  CONTACT_PHONE = 'Contact Phone',
  NOTES = 'Notes',
  TAGS = 'Tags',
  CUSTOM_FIELDS = 'Custom Fields'
}

export const ExportableFields = { ...RegularExportableFields, ...CustomFormsFeatures };
export type ExportableFieldsType = RegularExportableFields | CustomFormsFeatures;

export enum ReportDateFields {
  APPOINTMENT_DATE = 'Appointment Date',
  CREATION_DATE = 'Creation Date'
}

export enum AppointmentCreatorTypeReportOptions {
  WAREHOUSE_USER = 'Warehouse User',
  CARRIER = 'Carrier'
}

export enum WarehouseMetricGroupOptions {
  WAREHOUSE = 'warehouse',
  DOCK = 'dock',
  LOAD_TYPE = 'loadType',
  DIRECTION = 'direction'
}
