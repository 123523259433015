<template>
  <div class="d-flex flex-row justify-space-between mb-8">
    <logo :primary="primary"></logo>
    <div v-if="warehouse?.id" class="wh-name">
      {{ warehouse.name }}
    </div>
    <div v-else>
      <a href="https://opendock.com" target="_blank" rel="noopener noreferrer" class="learn-more">
        Learn more about Opendock
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    primary: {
      type: Boolean,
      default: false
    },
    warehouse: {
      type: Object,
      default: () => ({})
    }
  }
});
</script>

<style scoped>
.wh-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-align: right;
  color: #1e3036;
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learn-more {
  color: #f8fbfc;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration-line: underline;
}
</style>
