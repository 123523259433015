<template>
  <div>
    <div class="title">Opendock for Carriers</div>
    <div class="subtitle py-1">
      Search for warehouses and book dock appointments all from one convenient application
    </div>
    <a href="https://opendock.com" target="_blank" rel="noopener noreferrer" class="link">
      Learn more about Opendock
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped>
.title {
  color: #005a87;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #3d545c;
}

.link {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration-line: underline;
  color: #005a87;
}
</style>
