import { Info } from 'luxon';

export function getShortWeekdays(SundayFirst = true) {
  const weekdays = Info.weekdays('short');

  // Luxon returns weekdays starting from Monday, but we want Sunday to be the first day of the week
  const orderedWeekdays = SundayFirst
    ? [weekdays[weekdays.length - 1], ...weekdays.slice(0, -1)]
    : weekdays;

  return orderedWeekdays.map(weekday => weekday);
}

export function getLongWeekdayByShortName(shortName: string) {
  const shortWeekdays = Info.weekdays('short');
  const longWeekdays = Info.weekdays('long');

  const mapping = shortWeekdays.reduce((acc, shortDay, index) => {
    acc[shortDay] = longWeekdays[index];
    return acc;
  }, {} as Record<string, string>);
  if (!mapping[shortName]) {
    throw new Error('Invalid weekday');
  }

  return mapping[shortName];
}

export function mapLongWeekdaysToEnLocale(weekdays: string[]) {
  const currentLocaleLongWeekdays = Info.weekdays('long');
  const enLongWeekdays = Info.weekdays('long', { locale: 'en' });

  const mapping = currentLocaleLongWeekdays.reduce((acc, day, index) => {
    acc[day] = enLongWeekdays[index];
    return acc;
  }, {} as Record<string, string>);

  return weekdays.map(weekday => mapping[weekday] || weekday);
}
