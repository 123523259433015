import axios from 'axios';
import { neutronUrl } from '@/config';

const version = import.meta.env.PACKAGE_VERSION;

const client = axios.create({
  baseURL: neutronUrl,
  headers: {
    'X-UI-AppName': 'Comet',
    'X-UI-Version': version
  }
});

export default client;
