export const ISO3166Countries = {
  AFG: { name: 'Afghanistan', iso2: 'AF' },
  ALA: { name: 'Åland Islands', iso2: 'AX' },
  ALB: { name: 'Albania', iso2: 'AL' },
  DZA: { name: 'Algeria', iso2: 'DZ' },
  ASM: { name: 'American Samoa', iso2: 'AS' },
  AND: { name: 'Andorra', iso2: 'AD' },
  AGO: { name: 'Angola', iso2: 'AO' },
  AIA: { name: 'Anguilla', iso2: 'AI' },
  ATA: { name: 'Antarctica', iso2: 'AQ' },
  ATG: { name: 'Antigua and Barbuda', iso2: 'AG' },
  ARG: { name: 'Argentina', iso2: 'AR' },
  ARM: { name: 'Armenia', iso2: 'AM' },
  ABW: { name: 'Aruba', iso2: 'AW' },
  AUS: { name: 'Australia', iso2: 'AU' },
  AUT: { name: 'Austria', iso2: 'AT' },
  AZE: { name: 'Azerbaijan', iso2: 'AZ' },
  BHS: { name: 'Bahamas', iso2: 'BS' },
  BHR: { name: 'Bahrain', iso2: 'BH' },
  BGD: { name: 'Bangladesh', iso2: 'BD' },
  BRB: { name: 'Barbados', iso2: 'BB' },
  BLR: { name: 'Belarus', iso2: 'BY' },
  BEL: { name: 'Belgium', iso2: 'BE' },
  BLZ: { name: 'Belize', iso2: 'BZ' },
  BEN: { name: 'Benin', iso2: 'BJ' },
  BMU: { name: 'Bermuda', iso2: 'BM' },
  BTN: { name: 'Bhutan', iso2: 'BT' },
  BOL: { name: 'Bolivia', iso2: 'BO' },
  BES: { name: 'Bonaire, Sint Eustatius and Saba', iso2: 'BQ' },
  BIH: { name: 'Bosnia and Herzegovina', iso2: 'BA' },
  BWA: { name: 'Botswana', iso2: 'BW' },
  BVT: { name: 'Bouvet Island', iso2: 'BV' },
  BRA: { name: 'Brazil', iso2: 'BR' },
  IOT: { name: 'British Indian Ocean Territory', iso2: 'IO' },
  BRN: { name: 'Brunei Darussalam', iso2: 'BN' },
  BGR: { name: 'Bulgaria', iso2: 'BG' },
  BFA: { name: 'Burkina Faso', iso2: 'BF' },
  BDI: { name: 'Burundi', iso2: 'BI' },
  CPV: { name: 'Cabo Verde', iso2: 'CV' },
  KHM: { name: 'Cambodia', iso2: 'KH' },
  CMR: { name: 'Cameroon', iso2: 'CM' },
  CAN: { name: 'Canada', iso2: 'CA' },
  CYM: { name: 'Cayman Islands', iso2: 'KY' },
  CAF: { name: 'Central African Republic', iso2: 'CF' },
  TCD: { name: 'Chad', iso2: 'TD' },
  CHL: { name: 'Chile', iso2: 'CL' },
  CHN: { name: 'China', iso2: 'CN' },
  CXR: { name: 'Christmas Island', iso2: 'CX' },
  CCK: { name: 'Cocos (Keeling) Islands', iso2: 'CC' },
  COL: { name: 'Colombia', iso2: 'CO' },
  COM: { name: 'Comoros', iso2: 'KM' },
  COG: { name: 'Congo', iso2: 'CG' },
  COD: { name: 'Congo, Democratic Republic of the', iso2: 'CD' },
  COK: { name: 'Cook Islands', iso2: 'CK' },
  CRI: { name: 'Costa Rica', iso2: 'CR' },
  CIV: { name: 'Côte d"Ivoire', iso2: 'CI' },
  HRV: { name: 'Croatia', iso2: 'HR' },
  CUB: { name: 'Cuba', iso2: 'CU' },
  CUW: { name: 'Curaçao', iso2: 'CW' },
  CYP: { name: 'Cyprus', iso2: 'CY' },
  CZE: { name: 'Czechia', iso2: 'CZ' },
  DNK: { name: 'Denmark', iso2: 'DK' },
  DJI: { name: 'Djibouti', iso2: 'DJ' },
  DMA: { name: 'Dominica', iso2: 'DM' },
  DOM: { name: 'Dominican Republic', iso2: 'DO' },
  ECU: { name: 'Ecuador', iso2: 'EC' },
  EGY: { name: 'Egypt', iso2: 'EG' },
  SLV: { name: 'El Salvador', iso2: 'SV' },
  GNQ: { name: 'Equatorial Guinea', iso2: 'GQ' },
  ERI: { name: 'Eritrea', iso2: 'ER' },
  EST: { name: 'Estonia', iso2: 'EE' },
  SWZ: { name: 'Eswatini', iso2: 'SZ' },
  ETH: { name: 'Ethiopia', iso2: 'ET' },
  FLK: { name: 'Falkland Islands (Malvinas)', iso2: 'FK' },
  FRO: { name: 'Faroe Islands', iso2: 'FO' },
  FJI: { name: 'Fiji', iso2: 'FJ' },
  FIN: { name: 'Finland', iso2: 'FI' },
  FRA: { name: 'France', iso2: 'FR' },
  GUF: { name: 'French Guiana', iso2: 'GF' },
  PYF: { name: 'French Polynesia', iso2: 'PF' },
  ATF: { name: 'French Southern Territories', iso2: 'TF' },
  GAB: { name: 'Gabon', iso2: 'GA' },
  GMB: { name: 'Gambia', iso2: 'GM' },
  GEO: { name: 'Georgia', iso2: 'GE' },
  DEU: { name: 'Germany', iso2: 'DE' },
  GHA: { name: 'Ghana', iso2: 'GH' },
  GIB: { name: 'Gibraltar', iso2: 'GI' },
  GRC: { name: 'Greece', iso2: 'GR' },
  GRL: { name: 'Greenland', iso2: 'GL' },
  GRD: { name: 'Grenada', iso2: 'GD' },
  GLP: { name: 'Guadeloupe', iso2: 'GP' },
  GUM: { name: 'Guam', iso2: 'GU' },
  GTM: { name: 'Guatemala', iso2: 'GT' },
  GGY: { name: 'Guernsey', iso2: 'GG' },
  GIN: { name: 'Guinea', iso2: 'GN' },
  GNB: { name: 'Guinea-Bissau', iso2: 'GW' },
  GUY: { name: 'Guyana', iso2: 'GY' },
  HTI: { name: 'Haiti', iso2: 'HT' },
  HMD: { name: 'Heard Island and McDonald Islands', iso2: 'HM' },
  VAT: { name: 'Holy See', iso2: 'VA' },
  HND: { name: 'Honduras', iso2: 'HN' },
  HKG: { name: 'Hong Kong', iso2: 'HK' },
  HUN: { name: 'Hungary', iso2: 'HU' },
  ISL: { name: 'Iceland', iso2: 'IS' },
  IND: { name: 'India', iso2: 'IN' },
  IDN: { name: 'Indonesia', iso2: 'ID' },
  IRN: { name: 'Iran', iso2: 'IR' },
  IRQ: { name: 'Iraq', iso2: 'IQ' },
  IRL: { name: 'Ireland', iso2: 'IE' },
  IMN: { name: 'Isle of Man', iso2: 'IM' },
  ISR: { name: 'Israel', iso2: 'IL' },
  ITA: { name: 'Italy', iso2: 'IT' },
  JAM: { name: 'Jamaica', iso2: 'JM' },
  JPN: { name: 'Japan', iso2: 'JP' },
  JEY: { name: 'Jersey', iso2: 'JE' },
  JOR: { name: 'Jordan', iso2: 'JO' },
  KAZ: { name: 'Kazakhstan', iso2: 'KZ' },
  KEN: { name: 'Kenya', iso2: 'KE' },
  KIR: { name: 'Kiribati', iso2: 'KI' },
  PRK: { name: 'North Korea', iso2: 'KP' },
  KOR: { name: 'South Korea', iso2: 'KR' },
  KWT: { name: 'Kuwait', iso2: 'KW' },
  KGZ: { name: 'Kyrgyzstan', iso2: 'KG' },
  LAO: { name: 'Laos', iso2: 'LA' },
  LVA: { name: 'Latvia', iso2: 'LV' },
  LBN: { name: 'Lebanon', iso2: 'LB' },
  LSO: { name: 'Lesotho', iso2: 'LS' },
  LBR: { name: 'Liberia', iso2: 'LR' },
  LBY: { name: 'Libya', iso2: 'LY' },
  LIE: { name: 'Liechtenstein', iso2: 'LI' },
  LTU: { name: 'Lithuania', iso2: 'LT' },
  LUX: { name: 'Luxembourg', iso2: 'LU' },
  MAC: { name: 'Macao', iso2: 'MO' },
  MDG: { name: 'Madagascar', iso2: 'MG' },
  MWI: { name: 'Malawi', iso2: 'MW' },
  MYS: { name: 'Malaysia', iso2: 'MY' },
  MDV: { name: 'Maldives', iso2: 'MV' },
  MLI: { name: 'Mali', iso2: 'ML' },
  MLT: { name: 'Malta', iso2: 'MT' },
  MHL: { name: 'Marshall Islands', iso2: 'MH' },
  MTQ: { name: 'Martinique', iso2: 'MQ' },
  MRT: { name: 'Mauritania', iso2: 'MR' },
  MUS: { name: 'Mauritius', iso2: 'MU' },
  MYT: { name: 'Mayotte', iso2: 'YT' },
  MEX: { name: 'Mexico', iso2: 'MX' },
  FSM: { name: 'Micronesia', iso2: 'FM' },
  MDA: { name: 'Moldova', iso2: 'MD' },
  MCO: { name: 'Monaco', iso2: 'MC' },
  MNG: { name: 'Mongolia', iso2: 'MN' },
  MNE: { name: 'Montenegro', iso2: 'ME' },
  MSR: { name: 'Montserrat', iso2: 'MS' },
  MAR: { name: 'Morocco', iso2: 'MA' },
  MOZ: { name: 'Mozambique', iso2: 'MZ' },
  MMR: { name: 'Myanmar', iso2: 'MM' },
  NAM: { name: 'Namibia', iso2: 'NA' },
  NRU: { name: 'Nauru', iso2: 'NR' },
  NPL: { name: 'Nepal', iso2: 'NP' },
  NLD: { name: 'Netherlands', iso2: 'NL' },
  NCL: { name: 'New Caledonia', iso2: 'NC' },
  NZL: { name: 'New Zealand', iso2: 'NZ' },
  NIC: { name: 'Nicaragua', iso2: 'NI' },
  NER: { name: 'Niger', iso2: 'NE' },
  NGA: { name: 'Nigeria', iso2: 'NG' },
  NIU: { name: 'Niue', iso2: 'NU' },
  NFK: { name: 'Norfolk Island', iso2: 'NF' },
  MKD: { name: 'North Macedonia', iso2: 'MK' },
  MNP: { name: 'Northern Mariana Islands', iso2: 'MP' },
  NOR: { name: 'Norway', iso2: 'NO' },
  OMN: { name: 'Oman', iso2: 'OM' },
  PAK: { name: 'Pakistan', iso2: 'PK' },
  PLW: { name: 'Palau', iso2: 'PW' },
  PSE: { name: 'Palestine', iso2: 'PS' },
  PAN: { name: 'Panama', iso2: 'PA' },
  PNG: { name: 'Papua New Guinea', iso2: 'PG' },
  PRY: { name: 'Paraguay', iso2: 'PY' },
  PER: { name: 'Peru', iso2: 'PE' },
  PHL: { name: 'Philippines', iso2: 'PH' },
  PCN: { name: 'Pitcairn', iso2: 'PN' },
  POL: { name: 'Poland', iso2: 'PL' },
  PRT: { name: 'Portugal', iso2: 'PT' },
  PRI: { name: 'Puerto Rico', iso2: 'PR' },
  QAT: { name: 'Qatar', iso2: 'QA' },
  REU: { name: 'Réunion', iso2: 'RE' },
  ROU: { name: 'Romania', iso2: 'RO' },
  RUS: { name: 'Russia', iso2: 'RU' },
  RWA: { name: 'Rwanda', iso2: 'RW' },
  BLM: { name: 'Saint Barthélemy', iso2: 'BL' },
  SHN: { name: 'Saint Helena, Ascension and Tristan da Cunha', iso2: 'SH' },
  KNA: { name: 'Saint Kitts and Nevis', iso2: 'KN' },
  LCA: { name: 'Saint Lucia', iso2: 'LC' },
  MAF: { name: 'Saint Martin (French part)', iso2: 'MF' },
  SPM: { name: 'Saint Pierre and Miquelon', iso2: 'PM' },
  VCT: { name: 'Saint Vincent and the Grenadines', iso2: 'VC' },
  WSM: { name: 'Samoa', iso2: 'WS' },
  SMR: { name: 'San Marino', iso2: 'SM' },
  STP: { name: 'Sao Tome and Principe', iso2: 'ST' },
  SAU: { name: 'Saudi Arabia', iso2: 'SA' },
  SEN: { name: 'Senegal', iso2: 'SN' },
  SRB: { name: 'Serbia', iso2: 'RS' },
  SYC: { name: 'Seychelles', iso2: 'SC' },
  SLE: { name: 'Sierra Leone', iso2: 'SL' },
  SGP: { name: 'Singapore', iso2: 'SG' },
  SXM: { name: 'Sint Maarten (Dutch part)', iso2: 'SX' },
  SVK: { name: 'Slovakia', iso2: 'SK' },
  SVN: { name: 'Slovenia', iso2: 'SI' },
  SLB: { name: 'Solomon Islands', iso2: 'SB' },
  SOM: { name: 'Somalia', iso2: 'SO' },
  ZAF: { name: 'South Africa', iso2: 'ZA' },
  SGS: { name: 'South Georgia and the South Sandwich Islands', iso2: 'GS' },
  SSD: { name: 'South Sudan', iso2: 'SS' },
  ESP: { name: 'Spain', iso2: 'ES' },
  LKA: { name: 'Sri Lanka', iso2: 'LK' },
  SDN: { name: 'Sudan', iso2: 'SD' },
  SUR: { name: 'Suriname', iso2: 'SR' },
  SJM: { name: 'Svalbard and Jan Mayen', iso2: 'SJ' },
  SWE: { name: 'Sweden', iso2: 'SE' },
  CHE: { name: 'Switzerland', iso2: 'CH' },
  SYR: { name: 'Syria', iso2: 'SY' },
  TWN: { name: 'Taiwan', iso2: 'TW' },
  TJK: { name: 'Tajikistan', iso2: 'TJ' },
  TZA: { name: 'Tanzania', iso2: 'TZ' },
  THA: { name: 'Thailand', iso2: 'TH' },
  TLS: { name: 'Timor-Leste', iso2: 'TL' },
  TGO: { name: 'Togo', iso2: 'TG' },
  TKL: { name: 'Tokelau', iso2: 'TK' },
  TON: { name: 'Tonga', iso2: 'TO' },
  TTO: { name: 'Trinidad and Tobago', iso2: 'TT' },
  TUN: { name: 'Tunisia', iso2: 'TN' },
  TUR: { name: 'Turkey', iso2: 'TR' },
  TKM: { name: 'Turkmenistan', iso2: 'TM' },
  TCA: { name: 'Turks and Caicos Islands', iso2: 'TC' },
  TUV: { name: 'Tuvalu', iso2: 'TV' },
  UGA: { name: 'Uganda', iso2: 'UG' },
  UKR: { name: 'Ukraine', iso2: 'UA' },
  ARE: { name: 'United Arab Emirates', iso2: 'AE' },
  GBR: { name: 'United Kingdom', iso2: 'GB' },
  UMI: { name: 'United States Minor Outlying Islands', iso2: 'UM' },
  USA: { name: 'United States of America', iso2: 'US' },
  URY: { name: 'Uruguay', iso2: 'UY' },
  UZB: { name: 'Uzbekistan', iso2: 'UZ' },
  VUT: { name: 'Vanuatu', iso2: 'VU' },
  VEN: { name: 'Venezuela', iso2: 'VE' },
  VNM: { name: 'Vietnam', iso2: 'VN' },
  VGB: { name: 'Virgin Islands (British)', iso2: 'VG' },
  VIR: { name: 'Virgin Islands (U.S.)', iso2: 'VI' },
  WLF: { name: 'Wallis and Futuna', iso2: 'WF' },
  ESH: { name: 'Western Sahara', iso2: 'EH' },
  YEM: { name: 'Yemen', iso2: 'YE' },
  ZMB: { name: 'Zambia', iso2: 'ZM' },
  ZWE: { name: 'Zimbabwe', iso2: 'ZW' }
};

/**
 * Countries currently "supported" by Nova.
 */
export const ISO3166FilteredCountries = {
  USA: ISO3166Countries.USA,
  CAN: ISO3166Countries.CAN,
  MEX: ISO3166Countries.MEX,
  BRA: ISO3166Countries.BRA,
  PRI: ISO3166Countries.PRI,
  CHE: ISO3166Countries.CHE,
  DEU: ISO3166Countries.DEU,
  ESP: ISO3166Countries.ESP,
  GBR: ISO3166Countries.GBR,
  IRL: ISO3166Countries.IRL,
  ITA: ISO3166Countries.ITA,
  NLD: ISO3166Countries.NLD,
  KOR: ISO3166Countries.KOR,
  MLT: ISO3166Countries.MLT,
  NZL: ISO3166Countries.NZL,
  POL: ISO3166Countries.POL,
  SWE: ISO3166Countries.SWE,
  AUS: ISO3166Countries.AUS,
  TUR: ISO3166Countries.TUR,
  SGP: ISO3166Countries.SGP,
  PRT: ISO3166Countries.PRT,
  BEL: ISO3166Countries.BEL,
  PHL: ISO3166Countries.PHL,
  HUN: ISO3166Countries.HUN
};
