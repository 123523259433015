import { AppointmentStatus, IAppointment } from '.';
import { AssetVisitEventType, IAssetVisitEvent } from './asset-visit-event';
import { ISpotAssignment } from './yard';

export interface IAssetVisit {
  id: string;
  assetId: string;
  orgId: string;
  appointmentId: string;
  appointment: IAppointment;
  isPlanned: boolean;
  assetVisitEvents: IAssetVisitEvent[];
  spotAssignments: ISpotAssignment[];
}

export function isAssetVisitPlanned(assetVisit: IAssetVisit) {
  return (assetVisit.isPlanned && !isAssetVisitAppointmentPastArrived(assetVisit)) ?? false;
}

export function isAssetVisitUnplannedAndUnresolved(assetVisit: IAssetVisit) {
  return Boolean(
    !assetVisit.isPlanned && !assetVisit.appointmentId && !isAssetVisitCancelled(assetVisit)
  );
}

export function isAssetVisitUnplannedAndResolved(assetVisit: IAssetVisit): boolean {
  return Boolean(
    !assetVisit.isPlanned &&
      (assetVisit.appointmentId ||
        assetVisit.assetVisitEvents?.some(
          event => event.eventType === AssetVisitEventType.Canceled
        ))
  );
}

export function isAssetVisitAppointmentPastArrived(assetVisit: IAssetVisit): boolean {
  return Boolean(
    [AppointmentStatus.InProgress, AppointmentStatus.Completed].includes(
      assetVisit.appointment?.status
    )
  );
}

export function isAssetVisitDeparted(assetVisit: IAssetVisit): boolean {
  return assetVisit.assetVisitEvents
    ?.map(event => event.eventType)
    .includes(AssetVisitEventType.Departed);
}

export function isAssetVisitDocked(assetVisit: IAssetVisit): boolean {
  return assetVisit.assetVisitEvents
    ?.map(event => event.eventType)
    .includes(AssetVisitEventType.Docked);
}

export function isAssetVisitCancelled(assetVisit: IAssetVisit): boolean {
  return assetVisit.assetVisitEvents
    ?.map(event => event.eventType)
    .includes(AssetVisitEventType.Canceled);
}

export function isAssetVisitAssigned(assetVisit: IAssetVisit): boolean {
  return assetVisit.spotAssignments?.length > 0;
}

export function getPlannedAssetVisits(assetVisits: IAssetVisit[]): IAssetVisit[] {
  return assetVisits.filter(isAssetVisitPlanned);
}

export function getUnplannedAndUnresolvedAssetVisits(assetVisits: IAssetVisit[]): IAssetVisit[] {
  return assetVisits.filter(isAssetVisitUnplannedAndUnresolved);
}

export function getResolvedAssetVisits(assetVisits: IAssetVisit[]): IAssetVisit[] {
  return assetVisits.filter(
    assetVisit =>
      isAssetVisitUnplannedAndResolved(assetVisit) || isAssetVisitAppointmentPastArrived(assetVisit)
  );
}

export function getWaitingAssetVisits(assetVisits: IAssetVisit[]): IAssetVisit[] {
  return (
    assetVisits?.filter(
      assetVisit =>
        !isAssetVisitCancelled(assetVisit) &&
        !isAssetVisitDeparted(assetVisit) &&
        !isAssetVisitAssigned(assetVisit)
    ) || []
  );
}
