/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum CharacterKeys {
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  ENTER = 'Enter',
  SHIFT = 'Shift',
  CTRL = 'Control',
  ALT = 'Alt',
  PAUSE_BREAK = 'Pause',
  CAPS_LOCK = 'CapsLock',
  ESCAPE = 'Escape',
  SPACE = ' ',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',
  END = 'End',
  HOME = 'Home',
  LEFT_ARROW = 'ArrowLeft',
  UP_ARROW = 'ArrowUp',
  RIGHT_ARROW = 'ArrowRight',
  DOWN_ARROW = 'ArrowDown',
  INSERT = 'Insert',
  DELETE = 'Delete',
  KEY_0 = '0',
  KEY_1 = '1',
  KEY_2 = '2',
  KEY_3 = '3',
  KEY_4 = '4',
  KEY_5 = '5',
  KEY_6 = '6',
  KEY_7 = '7',
  KEY_8 = '8',
  KEY_9 = '9',
  KEY_A = 'a',
  KEY_B = 'b',
  KEY_C = 'c',
  KEY_D = 'd',
  KEY_E = 'e',
  KEY_F = 'f',
  KEY_G = 'g',
  KEY_H = 'h',
  KEY_I = 'i',
  KEY_J = 'j',
  KEY_K = 'k',
  KEY_L = 'l',
  KEY_M = 'm',
  KEY_N = 'n',
  KEY_O = 'o',
  KEY_P = 'p',
  KEY_Q = 'q',
  KEY_R = 'r',
  KEY_S = 's',
  KEY_T = 't',
  KEY_U = 'u',
  KEY_V = 'v',
  KEY_W = 'w',
  KEY_X = 'x',
  KEY_Y = 'y',
  KEY_Z = 'z',
  LEFT_META = 'Meta',
  RIGHT_META = 'Meta',
  SELECT = 'Select',
  NUMPAD_0 = '0',
  NUMPAD_1 = '1',
  NUMPAD_2 = '2',
  NUMPAD_3 = '3',
  NUMPAD_4 = '4',
  NUMPAD_5 = '5',
  NUMPAD_6 = '6',
  NUMPAD_7 = '7',
  NUMPAD_8 = '8',
  NUMPAD_9 = '9',
  MULTIPLY = '*',
  ADD = '+',
  SUBTRACT = '-',
  DECIMAL_POINT = '.',
  DIVIDE = '/',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',
  NUM_LOCK = 'NumLock',
  SCROLL_LOCK = 'ScrollLock',
  COLON = ':',
  SEMI_COLON = ';',
  EQUAL_SIGN = '=',
  COMMA = ',',
  DASH = '-',
  PERIOD = '.',
  FORWARD_SLASH = '/',
  GRAVE_ACCENT = '`',
  OPEN_BRACKET = '[',
  BACK_SLASH = '\\',
  CLOSE_BRACKET = ']',
  SINGLE_QUOTE = "'"
}
