import { createRouter, createWebHistory } from 'vue-router';

import Initial from '../views/Initial.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'initial',
      component: Initial
    },
    {
      path: '/gps-error',
      name: 'gps-error',
      component: () => import('../views/NoGpsAvailable.vue')
    },
    {
      path: '/appointment-error',
      name: 'appointment-error',
      props: true,
      component: () => import('../views/ErrorScreen.vue')
    },
    {
      path: '/warehouse-error',
      name: 'warehouse-error',
      props: true,
      component: () => import('../views/ErrorScreen.vue')
    },
    {
      path: '/completed',
      name: 'completed',
      props: true,
      component: () => import('../views/Completed.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../views/NotFound.vue')
    }
  ]
});

export default router;
