// @ts-strict-ignore
function splitLast(join: string): [string, string] {
  // converts 'foo.bar.baz' into => ['foo.bar', 'baz']
  const lastIndex = join.lastIndexOf('.');
  if (lastIndex === -1) {
    return [join, null];
  }
  const head = join.slice(0, lastIndex);
  const tail = join.slice(lastIndex + 1);
  return [head, tail];
}

export function exhaustJoin(join: string): string[] {
  const result: string[] = [];

  if (!join || typeof join !== 'string') {
    return [];
  }

  let keepGoing = true;
  while (keepGoing) {
    let tail;
    result.push(join);
    [join, tail] = splitLast(join);

    if (!tail) {
      keepGoing = false;
    }
  }

  return result.reverse();
}
