export const neutronUrl = import.meta.env.VITE_NEUTRON_URL;

export const lunaUrl = import.meta.env.VITE_LUNA_URL;

export const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;

export const environment = import.meta.env.MODE.toLowerCase().includes('production')
  ? 'production'
  : 'development';

export const unleashConfig = {
  url: import.meta.env.VITE_UNLEASH_URL,
  appName: import.meta.env.VITE_UNLEASH_APP_NAME,
  clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
  environment
};
