<template>
  <div class="v-input v-input--horizontal v-field--variant-solo">
    <label class="v-label v-field-label mx-4">{{ field.label }} - ({{ field.description }})</label>
    <div class="v-input__control">
      <datepicker
        :required="field.required"
        v-model="time"
        :timezone="timezone"
        time-picker-inline
        no-hours-overlay
        no-minutes-overlay
        class="mt-11 mb-3 ml-4"
        :state="!!time" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'custom-field-timestamp',
  mixins: [customFieldTypeMixin],
  data() {
    return {
      time: ''
    };
  },
  beforeMount() {
    if (this.value) {
      this.time = DateTime.fromISO(this.value, {
        zone: this.timezone
      });
    }
  },
  watch: {
    time(value) {
      this.value = value;
    }
  }
};
</script>
