import { isString } from 'class-validator';

const RenderModeEnum = Object.freeze({
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
});

export function isMobileDevice(): boolean {
  return /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isMobile(): boolean {
  const currentRenderMode = localStorage.getItem('renderMode');
  return isMobileDevice() && currentRenderMode !== RenderModeEnum.DESKTOP;
}

export function isThresholdErrorMessage(message: string): boolean {
  return (
    isString(message) &&
    message.startsWith('You must be within') &&
    (message.includes('before') || message.includes('after')) &&
    (message.includes('hour') || message.includes('minute')) &&
    message.endsWith('your appointment to be able to check-in')
  );
}
