import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      askingForLocation: false,
      calculatingLocation: false,
      latitude: 0,
      longitude: 0
    };
  },
  computed: {
    hasGpsSupport(): boolean {
      return navigator.geolocation !== undefined;
    }
  },
  methods: {
    async hasGivenPermission(): Promise<boolean> {
      const permission = await navigator.permissions.query({ name: 'geolocation' });

      return permission.state === 'granted';
    },
    async hasRejectedPermission(): Promise<boolean> {
      const permission = await navigator.permissions.query({ name: 'geolocation' });

      return permission.state === 'denied';
    },
    async askForLocation() {
      if (this.hasGpsSupport) {
        const hasGivenPermission = await this.hasGivenPermission();
        const hasRejectedPermission = await this.hasRejectedPermission();
        if (hasGivenPermission) {
          // If the user already gave permission to the browser, just go get it.
          this.retrieveLocation();
        } else if (hasRejectedPermission) {
          // If the user rejected permission to the browser it is a "dead-end",
          // unless they cleanup browser preferences, etc.
          // TODO: Go to new page with instructions or anything to help them solve it.
          this.onNoGpsAllowed();
        } else {
          this.askingForLocation = true;
        }
      }
    },
    retrieveLocation() {
      this.askingForLocation = false;
      this.calculatingLocation = true;
      navigator.geolocation.getCurrentPosition(this.onLocationRetrieved, this.onNoGpsAllowed);
    },
    onLocationRetrieved(coordinates: GeolocationPosition) {
      this.calculatingLocation = false;
      this.latitude = coordinates.coords.latitude;
      this.longitude = coordinates.coords.longitude;
      this.onLocationSuccess();
    },
    onLocationSuccess() {
      // Implement this on childs
    },
    onNoGpsAllowed() {
      this.calculatingLocation = false;
      this.$router.push('/gps-error');
    }
  }
});
