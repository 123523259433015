<template>
  <custom-field-input-base :field="field" v-model="value" type="text"></custom-field-input-base>
</template>

<script>
import customFieldTypeMixin from '@/components/custom_fields/mixins/customFieldTypeMixin';

export default {
  name: 'custom-field-string',
  mixins: [customFieldTypeMixin]
};
</script>
