<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title class="pl-6 pt-6"> Allow access to your location?</v-card-title>
      <v-card-text>
        By allowing access to your location we can make sure you arrived at the facility for
        check-in
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('disagree')">Disagree</v-btn>
        <v-btn color="primary" @click="$emit('agree')">Agree</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocationPermissionDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    close() {
      this.$emit('close');
      this.dialog = false;
    }
  },
  mounted() {
    this.dialog = this.visible;
  },
  watch: {
    visible(visible) {
      this.dialog = visible;
    }
  }
});
</script>
