import { isObject } from 'class-validator';

import type { WrapperType } from './globals';
import type { INovaEntity } from './base';
import type { IDock } from './dock';
import type { ICustomField } from './custom-field';
import type { IHasOrgId, IOrg } from './org';
import type { IHasSettings } from './settings';
import type { IHasSchedule } from './schedule';

export interface IAddress extends INovaEntity {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export interface IGeolocation {
  latitude: number;
  longitude: number;
}

export function formatFullAddress(address: IAddress) {
  if (isObject(address)) {
    return `${address.street ?? ''}, ${address.city ?? ''}, ${address.state ?? ''} ${
      address.zip ?? ''
    }`.trim();
  }
  return null;
}

export enum RefNumberValidationVersion {
  V1 = 'V1', // Includes "Google Sheet" mode
  V2 = 'V2'
}

export interface IWarehouse extends IAddress, IHasSchedule, IHasSettings, IHasOrgId {
  name: string;
  facilityNumber?: string;
  phone?: string;
  email?: string;
  notes?: string;
  instructions?: string;
  customApptFieldsTemplate: WrapperType<ICustomField>[];
  refNumberValidationVersion: RefNumberValidationVersion;
  refNumberValidationUrl?: string;
  refNumberValidationPasscode?: string;
  orgId: string;
  contactName?: string;
  org?: WrapperType<IOrg>;
  docks?: WrapperType<IDock>[];
  timezone?: string;
  allowCarrierScheduling: boolean;
  geolocation?: IGeolocation;
  country?: string;
  amenities?: AmenitiesEnum[];
  ppeRequirements?: PPERequirementsEnum[];
}

export enum AmenitiesEnum {
  LUMPER = 'Lumper services',
  RESTROOM = 'Drivers restroom',
  OVERNIGHT = 'Overnight parking',
  WIFI = 'Free Wi-Fi'
}

export enum PPERequirementsEnum {
  MASK = 'Face Mask',
  GLASSES = 'Safety Glasses',
  HAT = 'Hard Hat',
  BOOTS = 'Safety Boots',
  GLOVES = 'Gloves',
  VISIBLE_VEST = 'High Visibility Vest',
  PANTS = 'Long Pants',
  SLEEVE = 'Long Sleeves',
  NO_SMOKE = 'No Smoking'
}

export function hasWarehouseCheckinEnabled(warehouse: IWarehouse): boolean {
  return Boolean(warehouse?.geolocation);
}
