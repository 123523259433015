// @ts-strict-ignore
import moment from 'moment-timezone';

import { IVersioned } from './base';
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats, Weekday } from './chronon';
import * as ClockSpanLib from './clock-span';
import { getTwentyFourHourClockSpan, mergeAdjacentSpans } from './clock-span';
import * as IntervalLib from './interval';
import { DateTime, Info } from 'luxon';
import { upperFirst } from './js-helpers';

const weekdays = moment.weekdays().map(d => d.toLowerCase());
weekdays.push(weekdays.shift()); // start on monday

const validScheduleKeys = ['version', ...weekdays, 'closedIntervals'];
const allowedVersions = [1];

export const DEFAULT_SCHEDULE: ISchedule = {
  version: 1,
  monday: [{ end: '17:00', start: '8:00' }],
  tuesday: [{ end: '17:00', start: '8:00' }],
  wednesday: [{ end: '17:00', start: '8:00' }],
  thursday: [{ end: '17:00', start: '8:00' }],
  friday: [{ end: '17:00', start: '8:00' }],
  closedIntervals: []
};

export interface ISchedule extends IVersioned {
  sunday?: ClockSpanLib.IClockSpan[];
  monday?: ClockSpanLib.IClockSpan[];
  tuesday?: ClockSpanLib.IClockSpan[];
  wednesday?: ClockSpanLib.IClockSpan[];
  thursday?: ClockSpanLib.IClockSpan[];
  friday?: ClockSpanLib.IClockSpan[];
  saturday?: ClockSpanLib.IClockSpan[];
  closedIntervals?: IntervalLib.ITimeInterval[];
}

export function getFullyOpenSchedule(): ISchedule {
  return {
    version: 1,
    sunday: [getTwentyFourHourClockSpan()],
    monday: [getTwentyFourHourClockSpan()],
    tuesday: [getTwentyFourHourClockSpan()],
    wednesday: [getTwentyFourHourClockSpan()],
    thursday: [getTwentyFourHourClockSpan()],
    friday: [getTwentyFourHourClockSpan()],
    saturday: [getTwentyFourHourClockSpan()]
  };
}

export function getDefaultSchedule(): ISchedule {
  return { version: 1 };
}

export function getClosedScheduleTemplate(): ISchedule {
  return {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    version: 1
  };
}

export interface IHasSchedule {
  schedule?: ISchedule;
}

export function hasSchedule(obj: IHasSchedule): boolean {
  return Boolean(
    obj instanceof Object &&
      obj.schedule instanceof Object &&
      Object.keys(obj.schedule).length >= 2 &&
      Object.keys(obj.schedule).includes('version') &&
      Object.keys(obj.schedule)
        .filter(k => k !== 'version')
        .some(k => validScheduleKeys.includes(k))
  );
}

export const firstWeekOf2001 = IntervalLib.fromDates(
  '2001-01-01T00:00:00.000Z',
  '2001-01-08T00:00:00.000Z'
);

function findZuluMondayMidnight(d: Date, offset_days: number): Date {
  const m = moment.utc(d);

  if (!m.isValid()) {
    return null;
  }

  // Monday == dayOfWeek 1
  return m
    .isoWeekday(1 + offset_days)
    .startOf('isoWeek')
    .toDate();
}

export function isZMM(d: Date): boolean {
  const m = moment.utc(d);

  return m.isoWeekday() === 1 && [m.hours(), m.minutes(), m.seconds()].every(v => v === 0);
}

export function nextZMM(d: Date): Date {
  // Ugly, but works - short-circuit if the given date
  // is a ZMM. Note that prevZMM() doesn't need this since
  // the helper function takes care of that case.
  if (isZMM(d)) {
    return d;
  }

  return d && findZuluMondayMidnight(d, 7);
}

export function prevZMM(d: Date): Date {
  return d && findZuluMondayMidnight(d, 0);
}

// "Widen To Zulu Monday Midnight"
// Given an input interval, return the "widened" version of it that
// starts at the previous Zulu Monday Midnight (ZMM), and ends at the next
// ZMM.
export function widenToZMM(interval: IntervalLib.ITimeInterval): IntervalLib.ITimeInterval {
  const start = prevZMM(interval.start);
  const end = nextZMM(interval.end);

  return IntervalLib.fromDates(start, end);
}

// Returns: 'null' if schedule is valid, otherwise a detailed error message.
export function validateSchedule(schedule: ISchedule): string | null {
  const keys = Object.keys(schedule);

  if (!keys.includes('version')) {
    return "Schedule is missing key: 'version'";
  }

  if (!allowedVersions.includes(schedule.version)) {
    return `Schedule has invalid version: '${schedule.version}'`;
  }

  for (const key of keys) {
    // check for invalid key
    if (!validScheduleKeys.includes(key)) {
      return `Schedule has an invalid key: '${key}'`;
    }

    // check for invalid clock span
    if (weekdays.includes(key)) {
      const clockSpanList = schedule[key];
      if (!Array.isArray(clockSpanList)) {
        return `Schedule '${key}' type is incorrect: should be array'`;
      }

      for (const clockSpan of clockSpanList) {
        const errorMessage = ClockSpanLib.validateClockSpan(clockSpan);
        if (errorMessage) {
          return `Schedule '${key}' contains an invalid clock span: ${errorMessage}`;
        }
      }
    }

    // check for invalid closed dates
    if (key === 'closedIntervals') {
      const closedIntervals = schedule[key];
      if (!Array.isArray(closedIntervals)) {
        return `Schedule '${key}' type is incorrect: should be an array of DateTime interval`;
      }

      for (const closedInterval of closedIntervals) {
        const errorMessage = IntervalLib.validateInterval(closedInterval);
        if (errorMessage) {
          return `Schedule '${key}' contains an invalid DateTime interval: ${errorMessage}`;
        }
      }
    }
  }

  return null;
}

// Converts a "monday-based" user schedule into a monday-based interval list,
// starting at 2001-01-01 (which happens to be a monday).
export function to2001(schedule: ISchedule): IntervalLib.ITimeIntervalList {
  let result = [] as IntervalLib.ITimeIntervalList;

  weekdays.forEach(day => {
    if (!schedule[day]) {
      return;
    }

    result = result.concat(
      schedule[day].map(clockSpan => {
        const start = ClockSpanLib.clockTo2001Format(clockSpan.start, weekdays.indexOf(day) + 1);
        let end = ClockSpanLib.clockTo2001Format(clockSpan.end, weekdays.indexOf(day) + 1);

        // Round the availability to the next hour start
        // when the schedule ends at midnight, but we store 23:59
        if (end.getMinutes() === 59 && end.getUTCHours() === 23) {
          end = new Date(end.getTime() + IntervalLib.millisecondsIn.minute);
        }

        return IntervalLib.fromDates(start, end);
      })
    );
  });

  // Remove interval gaps that are 1 minute or smaller
  return IntervalLib.mergeSmallGaps(result, IntervalLib.millisecondsIn.minute);
}

export function adjustTzOffsetIfNegative(tzOffset_min: number): number {
  // If the timezone offset is negative, simply add
  // a week's worth of minutes to make it positive again,
  // but still equivalent (modulu arithmetic)
  const minutesInAWeek = 7 * 24 * 60;
  if (tzOffset_min < 0) {
    tzOffset_min += minutesInAWeek;
  }

  return tzOffset_min;
}

export function to2001Zulu(
  schedule: ISchedule,
  tzOffset_min: number
): IntervalLib.ITimeIntervalList {
  const hoops2001 = to2001(schedule);

  tzOffset_min = adjustTzOffsetIfNegative(tzOffset_min);

  return hoops2001.map(interval => IntervalLib.addOffset_min(interval, tzOffset_min));
}

// Same as to2001 above, but does the proper timezone "circular shift" so that it's
// in proper "Zulu Monday Midnight" format.
export function to2001ZMM(
  schedule: ISchedule,
  tzOffset_min: number
): IntervalLib.ITimeIntervalList {
  const hoops2001Zulu = to2001Zulu(schedule, tzOffset_min);

  tzOffset_min = adjustTzOffsetIfNegative(tzOffset_min);

  const shiftedWeek = IntervalLib.addOffset_min(firstWeekOf2001, tzOffset_min);

  // This is the "excess" time period past the firstWeekOf2001
  const excess = IntervalLib.fromDates(firstWeekOf2001.end, shiftedWeek.end);

  // Take whatever schedule pieces were "pushed out" into the excess
  // time period, and move them back a week, then prepend them to the output.
  let prependedIntervals = IntervalLib.intersectLists(hoops2001Zulu, [excess]);
  prependedIntervals = prependedIntervals.map(interval => IntervalLib.addOffset_week(interval, -1));

  // console.log('excess', excess);
  // console.log('prependedIntervals', prependedIntervals);

  const list = IntervalLib.intersectLists(
    [firstWeekOf2001],
    prependedIntervals.concat(hoops2001Zulu)
  );
  return IntervalLib.mergeSmallGaps(list, IntervalLib.millisecondsIn.minute);
}

// Takes a list of intervals in 2001ZMM format, and "reifies/instantiates" by shifting
// it to line up exactly with zmmInterval.
//
// TODO: Add more documentation for this confusing function!
export function reifyToZMMInterval(
  hoops2001ZMM: IntervalLib.ITimeIntervalList,
  zmmInterval: IntervalLib.ITimeInterval
): IntervalLib.ITimeIntervalList {
  const numWeeks = IntervalLib.duration_week(zmmInterval);

  // Calculate the amount of ms that the hoops need to be shifted into the future.
  // This should work since zmmInterval should always be after the year 2001
  const shift_ms = IntervalLib.duration_ms(
    IntervalLib.fromDates(firstWeekOf2001.start, zmmInterval.start)
  );
  hoops2001ZMM = hoops2001ZMM.map(ti => IntervalLib.addOffset_ms(ti, shift_ms));

  return IntervalLib.repeatWeekly(hoops2001ZMM, numWeeks);
}

export function reifyToZMMIntervalWithDSTOffset(
  hoops2001ZMM: IntervalLib.ITimeIntervalList,
  zmmInterval: IntervalLib.ITimeInterval,
  timezone: string
) {
  const reifiedIntervalList = reifyToZMMInterval(hoops2001ZMM, zmmInterval);
  const isDSTStarting = isDSTChangeStarting(reifiedIntervalList, timezone);
  if (isDSTStarting !== null) {
    reifiedIntervalList.forEach((ti, idx) => {
      ti.start = fixDSTOffsetInUTCDate(timezone, ti.start, isDSTStarting);
      // This is a workaround because the function to2001ZMM
      // will never return the sunday interval after midnight.
      // When the DST is starting and the hour selected for availability
      // is between 23:00 and 00:00 of sunday, we don't need to fix
      // the DST offset because the to2001ZMM function already did.
      const isEndBetweenElevenAndMidNight =
        ti.end.getUTCHours() === 0 || ti.end.getUTCHours() === 23;
      const isLastInterval = idx === reifiedIntervalList.length - 1;
      const isEndSunday = ti.end.getDay() === 0;

      if (isEndBetweenElevenAndMidNight && isEndSunday && isDSTStarting && isLastInterval) {
        return;
      }
      ti.end = fixDSTOffsetInUTCDate(timezone, ti.end, isDSTStarting);
    });
  }
  return reifiedIntervalList;
}

/**
 * Methods that work on IntervalLists need the start/end dates to be Date objects, not Date strings.
 * @param intervalList
 */
export function ensureIntervalListDatesAreDateObjects(intervalList: IntervalLib.ITimeIntervalList) {
  return intervalList.map(interval => ({
    start: new Date(interval.start),
    end: new Date(interval.end)
  }));
}

/*
 Check whether a particular date is under DST in a specific timezone
 */
export function isDateInDST(date: Date, timezone: string): boolean {
  return moment(date).tz(timezone).isDST();
}

/*
 Check whether a particular Time Interval is under DST changes in a specific timezone
 */
export function isAnyIntervalDateInDST(it: IntervalLib.ITimeInterval, timezone: string): boolean {
  return isDateInDST(it.start, timezone) || isDateInDST(it.end, timezone);
}

/*
 Check whether a particular Time Interval List is under DST changes in a specific timezone
 */
export function isDSTChangeBetweenIntervalList(
  itList: IntervalLib.ITimeIntervalList,
  timezone: string
): boolean {
  return new Set(itList.map(it => isAnyIntervalDateInDST(it, timezone))).size > 1;
}

/*
 Returns TRUE when the list of intervals is in the middle of a DST start (one hour less)
 Returns FALSE when the list of intervals is in the middle of a DST ending (one hour plus)
 Returns NULL when the entire list is under DST or has no DST
 */
export function isDSTChangeStarting(
  itList: IntervalLib.ITimeIntervalList,
  timezone: string
): boolean | null {
  if (isDSTChangeBetweenIntervalList(itList, timezone)) {
    // if the first TimeInterval dates are not on DST
    // we can assume that the DST change is starting since
    // it is ordered by date
    return !isAnyIntervalDateInDST(itList[0], timezone);
  }
  return null;
}

/*
 Returns in milliseconds a DST offset for a specific timezone
 */
export function getDSTOffsetMs(timezone: string, date: Date): number {
  const janOffset = moment.tz({ year: date.getFullYear(), month: 0, day: 1 }, timezone).utcOffset();
  const junOffset = moment.tz({ year: date.getFullYear(), month: 5, day: 1 }, timezone).utcOffset();

  return Math.abs(junOffset - janOffset) * IntervalLib.millisecondsIn.minute;
}

/*
 * Returns a new Date with the DST correction to render the HOOPs without
 * shifting time, let's say an availability call is asked two days after a
 * DST starts or after a DST ends, the availability times will shift causing
 * the calendar to have different time based on the DST changes when we should
 * be agnostic to the DST in this case.
 */
export function fixDSTOffsetInUTCDate(
  timezone: string,
  date: Date,
  subtractOffset: boolean | null
): Date {
  const dstOffsetMs = getDSTOffsetMs(timezone, date);

  if (isDateInDST(date, timezone)) {
    if (subtractOffset) {
      return new Date(date.getTime() - dstOffsetMs);
    }
  } else if (subtractOffset === false) {
    return new Date(date.getTime() + dstOffsetMs);
  }

  return date;
}

// WARNING: unlike most other functions in this library,
// this one mutates its argument as opposed to returning a
// new object.
export function mutation_mergeDailyGaps(schedule: ISchedule): void {
  for (const weekday of Object.values(Weekday)) {
    const w = weekday.toLowerCase();
    if (schedule[w]) {
      schedule[w] = mergeAdjacentSpans(schedule[w], IntervalLib.millisecondsIn.minute);
    }
  }
}

export function formatScheduleClock(time: string, enableMilitaryTime: boolean): string {
  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0], 10);
  const minute = parseInt(timeParts[1], 10);
  const dateTime = DateTime.now().set({ hour, minute }).toISO();
  return formatDateTimeWithMilitarySupport(
    dateTime,
    null,
    LuxonDateTimeFormats.Extended12HrTimeAMPM,
    enableMilitaryTime,
    LuxonDateTimeFormats.Extended24HrTime
  );
}
export function getShortDayName(day: string) {
  const uppercaseDay = upperFirst(day);
  const daysOfWeek = [...Info.weekdays('long'), ...Info.weekdays('short')];
  if (!daysOfWeek.includes(uppercaseDay)) {
    return `'${day}' is not a valid day of the week.`;
  }
  return uppercaseDay.substring(0, 3);
}
