import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import mixpanelMixin from './components/mixins/mixpanelMixin';
import apiErrorsMixin from '@/components/mixins/apiErrorsMixin';
import { loadFonts } from './plugins/webfontloader';
import * as CoreLib from '@nova/core';
import VueTelInput from 'vue3-tel-input';
import Datepicker from '@vuepic/vue-datepicker';
import axiosClient from '@/api/axiosClient';
import featureFlag from './plugins/featureFlag';
import store from './store';
import * as storeMixin from './store/mixin';

import initSentry from 'sentry-plugin';

import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-tel-input/dist/vue3-tel-input.css';

loadFonts();

const app = createApp(App);

app.config.globalProperties.novaCore = CoreLib;
app.config.globalProperties.axiosClient = axiosClient;

app.use(VueTelInput);
app.component('datepicker', Datepicker);

const sentryConfig = {
  sentry_dsn: import.meta.env.VITE_SENTRY_DSN,
  server_env: import.meta.env.MODE,
  nova_version: import.meta.env.PACKAGE_VERSION,
  neutron_url: import.meta.env.VITE_NEUTRON_URL,
  sentry_traces_sample_rate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 1
};

const components = import.meta.glob('./components/**/*.vue', { eager: true });
Object.entries(components).forEach(([path, definition]: [any, any]) => {
  // Get name of component, based on filename
  // "./components/Fruits.vue" will become "Fruits"
  const componentName = path
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  // Register component on this Vue instance
  app.component(componentName, definition.default);
});

initSentry(app, router, sentryConfig);

app
  .use(router)
  .use(vuetify)
  .use(store)
  .mixin(storeMixin)
  .mixin(mixpanelMixin)
  .mixin(apiErrorsMixin)
  .use(featureFlag)
  .mount('#app');
