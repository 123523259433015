// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';

const opendockTheme = {
  dark: false,
  colors: {
    primary: '#FFA333',
    secondary: '#0E4059',
    accent: '#005A87',
    error: '#f24848',
    disabled: '#F5F5F5',
    disabledgrey: '#f5f5f5',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
};

export default createVuetify({
  theme: {
    defaultTheme: 'opendockTheme',
    themes: {
      opendockTheme
    }
  }
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
