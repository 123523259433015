export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    fieldIcon: {
      type: String,
      required: false,
      default: ''
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    timezone: {
      type: String,
      required: false
    }
  },
  methods: {
    isDocumentLikeField() {
      return [
        this.novaCore.CustomFieldType.Document,
        this.novaCore.CustomFieldType.MultiDocument
      ].includes(this.field.type);
    }
  },
  computed: {
    validationRules() {
      return [
        v => {
          if (!v || v?.length === 0) {
            return this.field.required ? `${this.field.label} is required` : true;
          }
          return (
            this.isDocumentLikeField() ||
            this.novaCore.isValidCustomField(this.field) ||
            `${this.field.label} is invalid`
          );
        }
      ];
    }
  },
  data() {
    return {
      value: this.field.value,
      dropDownValues: this.field.dropDownValues,
      disallowedNumberCharacters: ['e', '', '+', '_', '=', '.']
    };
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    }
  }
};
