import { INovaEntity } from './base';

export interface ICompany extends INovaEntity {
  name: string;
  scac: string;
  mc: string;
  usdot: string;
  type?: CompanyType;
}

export enum CompanyType {
  BROKER = 'type_broker',
  CARRIER = 'type_carrier',
  CARRIER_BROKER = 'type_carrier_broker',
  FORWARDER = 'type_forwarder'
}
