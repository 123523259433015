interface ILimits {
  [k: string]: {
    value: number;
    description: string;
  };
}

export const GlobalLimits: ILimits = {
  MAX_STORAGE_FILE_SIZE_BYTES: {
    value: 50 * 1024 * 1024,
    description: 'The maximum allowed file size, in bytes.'
  },
  MAX_LOAD_TYPES_PER_WAREHOUSE: {
    value: 50,
    description: 'The maximum number of Loadtypes allowed per Warehouse.'
  },
  MAX_MAX_CARRIER_LEADTIME_LIMIT_HRS: {
    value: 365 * 24,
    description:
      'The maximum "max carrier lead time", in hours - the setting on the Dock cannot exceed this value.'
  },
  MAX_MIN_CARRIER_LEADTIME_LIMIT_HRS: {
    value: 1000,
    description:
      'The maximum "minimum carrier lead time", in hours - the setting on the Dock cannot exceed this value.'
  },
  MAX_CAPACITY_DOCKS: {
    value: 10,
    description: 'The maximum amount of child capacity Docks that can be created, per Dock.'
  },
  MAX_RECURRING_APPOINTMENT_WEEKS: {
    value: 105,
    description:
      'The maximum amount weeks into the future that you can book a Recurring Appointment Series for.'
  },
  MULTI_DOC_MAX_FILES_PER_APPOINTMENT: {
    value: 10,
    description: 'The maximum number of files that can be uploaded together.'
  },
  MAX_CUSTOM_TAGS_PER_ORG: {
    value: 128,
    description: 'The maximum number of custom tags that can be created, per Org.'
  },
  MAX_RESERVE_DURATION_MIN: {
    value: 1440,
    description: "The maximum number of minutes a Reserve's duration can be upon creation."
  },
  MAX_WAREHOUSE_PER_USER: {
    value: 70,
    description: 'The maximum number of warehouses an user can be part of.'
  },
  MAX_WAREHOUSE_PER_GROUP: {
    value: 50,
    description: 'The maximum number of warehouses a group can have.'
  },
  MIN_RESCHEDULE_MINUTES_FOR_NOTIFICATION: {
    value: 2,
    description:
      'The minimum number of minutes between the current and rescheduled "start" to initiate a notification'
  },
  MAX_INTERVAL_TRIM_FOR_CARRIERS: {
    value: 30,
    description:
      "The maximum number of intervals that can be trimmed from the beginning of the Warehouse's availability"
  },
  MAX_REPORTING_FILTER_SELECTIONS: {
    value: 25,
    description:
      'The maximum number of items that can be selected for each reporting filter entity.'
  },
  MAX_SMS_LENGTH: {
    value: 320,
    description: 'The maximum length allowed for an SMS'
  }
};
